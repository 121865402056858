import { Box, Container, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Char from '../generals/Char';
import EmptyResponse from '../responses/EmptyResponse';
import EmptyResponseType from '../responses/EmptyResponseType';
import PhasesResponse from '../responses/Phases';
import { ContinueVocab2EndpointPostPromise } from '../routes/ContinueVocab';
import { Language2EndpointGetHook } from '../routes/Language';
import { Phases2EndpointGetHook } from '../routes/Phases';
import VocabsOverview from './VocabsOverview';

interface Props {
    speak: (text: string, language?: string) => void;
}

function Vocabs(props: Props) {
    const { baseLanguage, targetLanguage } = useParams();
    const navigate = useNavigate();

    const [translations, setTranslations] = useState({
        noVocabs: '',
        iwascorrect: '',
        iwaswrong: '',
        back: '',
        currentVocab: '',
    });

    const translationsResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));

    useEffect(() => {
        if (translationsResponse.data) {
            setTranslations(translationsResponse.data.translations);
        }
    }, [translationsResponse.data]);

    const [phasesState, setPhasesState] = useState<PhasesResponse | EmptyResponseType>(EmptyResponse);

    const phasesResponse = Phases2EndpointGetHook(baseLanguage ?? '', targetLanguage ?? '');

    useEffect(() => {
        if (phasesResponse.data) {
            setPhasesState(phasesResponse);
        }
    }, [phasesResponse.data]);

    const [phaseIndex, setPhaseIndex] = useState(0);

    const [showSolution, setShowSolution] = useState(false);

    const correctAudio = new Audio('/correct_piano.mp3');
    const incorrectAudio = new Audio('/incorrect_piano.mp3');

    const iDidCorrect = (phase: string) => {
        const promise = ContinueVocab2EndpointPostPromise(phase, 'true');
        setShowSolution(false);
        setPhaseIndex(phaseIndex + 1);
        correctAudio.play();

        promise.then(() => {
        });
    };

    const iDidWrong = (phase: string, index: number) => {
        const promise = ContinueVocab2EndpointPostPromise(phase, 'false');
        incorrectAudio.play();

        setShowSolution(false);
        setPhaseIndex(phaseIndex + 1);

        promise.then(() => {
            reAddPhase(index);
        });
    };

    const reAddPhase = (phaseIndex: number) => {
        // Keep the current phases, but add it again to the end of the list.
        const phase = phasesState.data.phases[phaseIndex];

        const newPhases = phasesState.data.phases;
        newPhases.push(phase);

        setPhasesState({
            ...phasesState,
            data: {
                ...phasesState.data,
                phases: newPhases,
            },
        });
    };

    useEffect(() => {
        if (phasesState.status === 200 && (phasesState.data.phases.length === 0 || phaseIndex >= phasesState.data.phases.length)) {
            navigate(`/vocab/${baseLanguage}/${targetLanguage}`);
        }
    }, [phasesState, phaseIndex, navigate, baseLanguage, targetLanguage]);

    return (
        <>
            <Container
                maxWidth='sm'
            >
                {phasesState.status === 200 && phaseIndex < phasesState.data.phases.length &&
                    <Box
                    >
                        <Typography
                            variant='h6'
                            component='h2'
                            gutterBottom
                            sx={{
                                textAlign: 'center',
                                marginTop: 2,
                            }}
                            color='text.primary'
                        >
                            {translations.currentVocab}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: useTheme().palette.grey[100],
                                borderRadius: 2,
                                cursor: !showSolution ? 'pointer' : 'default',
                                height: 350,
                                userSelect: 'none',
                                position: 'relative',
                                textAlign: 'center',
                            }}
                            onClick={() => {
                                if (!showSolution) {
                                    props.speak(phasesState.data.phases[phaseIndex].matchingWord.targetWord, targetLanguage);
                                    setShowSolution(true);
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    zIndex: 10,
                                    marginTop: 10,
                                    pointerEvents: 'none',
                                }}
                            >
                                <Typography
                                    variant='h4'
                                    component='h1'
                                    gutterBottom
                                    sx={{
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                    textAlign='center'
                                    color='text.primary'
                                >
                                    <Char
                                        vocab={phasesState.data.phases[phaseIndex].matchingWord}
                                        language={targetLanguage!}
                                        height='60px'
                                        width='60px'
                                        sx={{
                                            filter: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5))',
                                        }}
                                    />
                                    {!showSolution && phasesState.data.phases[phaseIndex].matchingWord.baseWord}
                                    {showSolution &&
                                        <>
                                            {phasesState.data.phases[phaseIndex].matchingWord.targetWord}
                                            {phasesState.data.phases[phaseIndex].matchingWord.transliteration !== '' &&
                                                <Typography
                                                    variant='h6'
                                                    component='h2'
                                                    gutterBottom
                                                    sx={{
                                                        fontWeight: 'bold',
                                                    }}
                                                    color='text.primary'
                                                >
                                                    [{phasesState.data.phases[phaseIndex].matchingWord.transliteration}]
                                                </Typography>
                                            }
                                        </>
                                    }
                                </Typography>
                            </Box>
                            {showSolution &&
                                <>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            borderTopLeftRadius: '8px',
                                            borderBottomLeftRadius: '8px',
                                            width: '50%',
                                            height: '100%',
                                            backgroundColor: '#FF66662C',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'end',
                                            color: 'white',
                                            fontSize: 30,
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => iDidWrong(phasesState.data.phases[phaseIndex].id, phaseIndex)}
                                    >
                                        <Typography
                                            variant='caption'
                                            component='h2'
                                            gutterBottom
                                            sx={{
                                                fontWeight: 'bold',
                                                color: useTheme().palette.grey[600],
                                            }}
                                        >
                                            {translations.iwaswrong}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            width: '50%',
                                            borderTopRightRadius: '8px',
                                            borderBottomRightRadius: '8px',
                                            height: '100%',
                                            backgroundColor: '#66CC662C',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'end',
                                            color: 'white',
                                            fontSize: 30,
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => iDidCorrect(phasesState.data.phases[phaseIndex].id)}
                                    >
                                        <Typography
                                            variant='caption'
                                            component='h2'
                                            gutterBottom
                                            sx={{
                                                fontWeight: 'bold',
                                                color: useTheme().palette.grey[600],
                                            }}
                                        >
                                            {translations.iwascorrect}
                                        </Typography>
                                    </Box>
                                </>
                            }
                        </Box>
                    </Box>
                }
            </Container>
        </>
    );
}

export default Vocabs;
