import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from './generals/theme';
import Join from './Join';
import EmptyResponse from './responses/EmptyResponse';
import EmptyResponseType from './responses/EmptyResponseType';
import StateResponse from './responses/State';
import { Language2EndpointGetHook } from './routes/Language';
import { State2EndpointGetHook } from './routes/State';

function Welcome() {
    const navigate = useNavigate();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [translations, setTranslations] = useState({
        email: '',
        token: '',
        youReceivedAnEmail: '',
        emailText: '',
        chooseLanguage: '',
        welcome: '',
        language: '',
        sendEmail: '',
        verifyToken: '',
        english: '',
        german: '',
        spanish: '',
        italian: '',
        dutch: '',
        portuguese: '',
        french: '',
        ialreadyhaveanaccount: '',
        weHaveLotsOfCourses: '',
        iWantToLearn: '',
        theEasyWayToLearnALanguage: '',
        getStarted: '',
        login: '',
        resendToken: '',
        onetimepin: '',
        variousLearningMethods: '',
        VariousLearningMethodsWillDriveYouText: '',
    });

    const translationResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));

    useEffect(() => {
        if (translationResponse.data) {
            setTranslations(translationResponse.data.translations);
        }
    }, [translationResponse.data]);

    const [stateState, setStateState] = useState<StateResponse | EmptyResponseType>(EmptyResponse);

    const stateResponse = State2EndpointGetHook();

    useEffect(() => {
        if (stateResponse.data) {
            setStateState(stateResponse);
        }
    }, [stateResponse.data]);

    if (stateState.status === 200 && stateState.userState.isLogin === true && stateState.userState.userModel.isVerified === true) {
        navigate('/home');
    }

    const [currentLanguageState, setCurrentLanguageState] = useState('english');

    return (
        <>
            {!isMobile &&
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: 82.5,
                        backgroundColor: theme.palette.grey[200],
                    }}
                >
                </Box>
            }
            {stateState.status === 200 &&
                <Box
                    sx={{
                        marginBottom: 18,
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: theme.palette.grey[200],
                            width: '100%',
                            py: 4,
                        }}
                    >
                        <Container
                            maxWidth='lg'
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                            }}
                        >
                            <Box
                                sx={{
                                    width: { xs: 'auto', md: '50%' },
                                }}
                            >
                                <Typography
                                    variant='h5'
                                    textAlign='left'
                                    sx={{
                                        marginBottom: '1rem',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {translations.welcome}
                                </Typography>
                                <Typography
                                    variant='h6'
                                    textAlign='left'
                                    sx={{
                                        marginBottom: '1rem',
                                    }}
                                >
                                    {translations.weHaveLotsOfCourses}
                                </Typography>
                            </Box>
                            {!isMobile &&
                                <Box
                                    sx={{
                                        width: { xs: 'auto', md: '50%' },
                                    }}
                                >
                                    <img
                                        src="/lection.png"
                                        alt="lection"
                                        style={{
                                            maxWidth: 400,
                                            borderRadius: 5,
                                            border: '1px solid rgba(0, 0, 0, 0.12)',
                                        }}
                                    />
                                </Box>
                            }
                        </Container>
                    </Box>
                    <Container maxWidth='lg'>
                        <Box
                            sx={{
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                marginY: isMobile ? '1rem' : '5rem',
                                padding: 2,
                            }}
                        >
                            <Join
                                currentLanguageState={currentLanguageState}
                                translations={translations}
                            />
                        </Box>
                    </Container>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.grey[200],
                            width: '100%',
                            py: 4,
                        }}
                    >
                        <Container
                            maxWidth='lg'
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                            }}
                        >
                            {!isMobile &&
                                <Box
                                    sx={{
                                        width: { xs: 'auto', md: '50%' },
                                    }}
                                >
                                    <img
                                        src="/matching.png"
                                        alt="lection"
                                        style={{
                                            maxWidth: 400,
                                            borderRadius: 5,
                                            border: '1px solid rgba(0, 0, 0, 0.12)',
                                        }}
                                    />
                                </Box>
                            }
                            <Box
                                sx={{
                                    width: { xs: 'auto', md: '50%' },
                                }}
                            >
                                <Typography
                                    variant='h5'
                                    textAlign='left'
                                    sx={{
                                        marginBottom: '1rem',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {translations.variousLearningMethods}
                                </Typography>
                                <Typography
                                    variant='h6'
                                    textAlign='left'
                                    sx={{
                                        marginBottom: '1rem',
                                    }}
                                >
                                    {translations.VariousLearningMethodsWillDriveYouText}
                                </Typography>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            }
        </>
    );
}

export default Welcome;
