import { SkipNextRounded } from '@mui/icons-material';
import { Box, ButtonBase, Card, CardContent, CardMedia, Tooltip, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { theme } from '../generals/theme';

interface Props {
    title: string;
    subText: string;
    tooltip?: string;
    onClick?: () => void;
    media?: JSX.Element;
    skip?: boolean;
    translations: any;
}

function CustomCard(props: Props) {
    const isMobile = window.innerWidth <= 600;

    const navigate = useNavigate();

    return (
        <Tooltip
            title={props.tooltip}
            placement='right'
        >
            <ButtonBase
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: isMobile ? '100%' : '55%',
                    userSelect: 'none',
                    transition: 'transform 0.2s',
                    cursor: props.onClick ? 'pointer' : 'not-allowed',
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    borderRadius: 2,
                    marginBottom: 1,
                }}
                onClick={() => {
                    if (props.onClick) {
                        props.onClick();
                    }
                }}
            >
                <Card
                    sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: 2,
                        boxShadow: 'none',
                        backgroundColor: props.onClick
                            ? 'rgba(0, 0, 255, 0.05)'
                            : theme.palette.background.default,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            height: '100%',
                        }}
                    >
                        <CardMedia
                            sx={{
                                position: 'relative',
                                width: { md: 150, xs: 100 },
                                height: 'auto',
                                backgroundPosition: 'center',
                                objectFit: 'contain',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexShrink: 0,
                            }}
                        >
                            {props.media && props.media}
                        </CardMedia>
                        <CardContent
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexGrow: 1,
                                padding: '24px !important',
                                height: '100%',
                            }}
                        >
                            <Box>
                                <Typography
                                    gutterBottom
                                    variant={isMobile ? 'subtitle1' : 'h5'}
                                    component='h2'
                                    color='text.primary'
                                    sx={{
                                        display: 'flex',
                                        textAlign: 'left',
                                    }}
                                >
                                    {props.title}
                                </Typography>
                                <Typography
                                    variant='body2'
                                    color='text.secondary'
                                    sx={{
                                        display: 'flex',
                                        textAlign: 'left',
                                    }}
                                >
                                    {props.subText}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    marginLeft: 'auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                }}
                            >
                                {props.skip &&
                                    <Tooltip
                                        title={props.translations.skipToHere}
                                    >
                                        <SkipNextRounded
                                            sx={{
                                                fontSize: 40,
                                            }}
                                            onClick={props.onClick}
                                        />
                                    </Tooltip>
                                }
                            </Box>
                        </CardContent>
                    </Box>
                </Card>
            </ButtonBase>
        </Tooltip>
    );
}

export default CustomCard;
